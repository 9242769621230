<template>
  <div>
    <h2 class="text-lg font-medium text-gray-900">Order summary</h2>

    <div class="mt-4 bg-white border border-gray-400 rounded-lg shadow-sm">
      <h3 class="sr-only">Items in your cart</h3>
      <ul role="list" class="divide-y divide-gray-200">
        <li v-for="product in products" :key="product._id" class="flex py-6 px-4 sm:px-6 relative">
          <router-link :to="`/product/${product.productId}`" class="flex-shrink-0 w-20 rounded-md">
            <img v-lazy="product.image" class="" />
          </router-link>

          <div class="ml-6 flex-1 flex flex-col">
            <div class="min-w-0 flex-1">
              <h4 class="text-sm">
                <a :href="product.href" class="font-medium text-primary hover:text-gray-800">
                  {{ product.name }}
                </a>
              </h4>
              <p class="mt-1 text-sm text-gray-500">
                {{ product.productNum }}
              </p>
              <p class="mt-1 text-sm text-gray-500">
                {{ product.size }}
              </p>
            </div>

            <div class="flex-1 pt-2 flex items-end justify-between">
              <p class="mt-1 text-sm font-medium text-gray-900" v-if="product.price == 0">Free</p>

              <p class="mt-1 text-sm font-medium text-gray-900" v-else>
                ${{ product.price.toFixed(2) }}
              </p>

              <div class="ml-4">
                <label for="quantity" class="sr-only">Quantity</label>
                {{ product.quantity }}
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="border-t border-gray-200 py-6">
        <OrderTotals
          :products="products"
          :shipping="shipping"
          :discount="discount"
          :taxRate="taxRate"
          :storeCredit="storeCredit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrderTotals from '@/components/shared/NewOrderTotals.vue';
export default {
  props: {
    products: Array,
    shipping: Number,
    discount: Number,
    taxRate: Number,
    storeCredit: Number,
  },
  components: { OrderTotals },
};
</script>

<style></style>
