export default [
  82930,
  84003,
  84004,
  84005,
  84006,
  84010,
  84013,
  84014,
  84015,
  84017,
  84018,
  84020,
  84022,
  84022,
  84024,
  84025,
  84028,
  84029,
  84032,
  84033,
  84034,
  84036,
  84037,
  84038,
  84040,
  84041,
  84042,
  84043,
  84044,
  84045,
  84047,
  84049,
  84050,
  84054,
  84055,
  84056,
  84057,
  84058,
  84060,
  84061,
  84062,
  84064,
  84065,
  84067,
  84069,
  84070,
  84071,
  84074,
  84075,
  84080,
  84081,
  84082,
  84083,
  84083,
  84084,
  84086,
  84087,
  84088,
  84092,
  84093,
  84094,
  84095,
  84096,
  84097,
  84098,
  84101,
  84102,
  84103,
  84104,
  84105,
  84106,
  84107,
  84108,
  84109,
  84111,
  84112,
  84113,
  84115,
  84116,
  84117,
  84118,
  84119,
  84120,
  84121,
  84123,
  84124,
  84128,
  84144,
  84180,
  84304,
  84305,
  84308,
  84310,
  84315,
  84317,
  84318,
  84319,
  84320,
  84321,
  84325,
  84326,
  84327,
  84328,
  84332,
  84333,
  84335,
  84338,
  84339,
  84341,
  84401,
  84403,
  84404,
  84405,
  84414,
  84526,
  84601,
  84604,
  84606,
  84626,
  84628,
  84629,
  84633,
  84639,
  84645,
  84648,
  84651,
  84653,
  84655,
  84656,
  84660,
  84663,
  84664,
];
