<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="pb-6">
    <form
      @submit.prevent="updateUser"
      class="space-y-6 sm:px-6 lg:px-0 sm:pb-6 lg:col-span-9 max-w-4xl mx-auto"
    >
      <div class="bg-white rounded-lg shadow overflow-hidden relative">
        <header>
          <div class="bg-primary pb-25 overflow-hidden items-center h-24 sm:h-32 absolute w-full">
            <div
              class="inset-y-0 absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0"
              aria-hidden="true"
            >
              <svg
                class="flex-shrink-0 -mt-32"
                width="1750"
                height="308"
                viewBox="0 0 1750 308"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path opacity=".75" d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#00A0B1" />
                <path opacity=".75" d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#005785" />
              </svg>
              <div class="flex-grow bg-light-blue-800 bg-opacity-75"></div>
            </div>
          </div>
          <div class="p-5 flex">
            <div class="relative">
              <img
                v-if="user.avatar"
                :src="user.avatar"
                :alt="`${user.name}'s profile picture`"
                class="rounded-full mr-2 h-16 w-16 md:h-20 md:w-20"
              />
              <div
                v-else
                class="rounded-full mr-2 h-16 w-16 md:h-20 md:w-20 text-white bg-gray-100 text-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex-shrink-0 h-16 w-16 md:h-20 md:w-20 rounded-full"
                  viewBox="0 0 20 20"
                  fill="gray"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="absolute right-0 top-0">
                <button
                  type="button"
                  @click="showUploader = true"
                  class="rounded-full bg-gray-200 px-2 py-1"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </button>
              </div>
            </div>
            <div class="flex flex-col pl-4 z-10 text-white">
              <span v-if="user.permissionLevel === 'Commercial'" class="text-sm">
                Pro Account
              </span>
              <span v-else class="text-white text-sm">Full Name</span>
              <h2 class="hidden md:block">
                {{ user.name }}
              </h2>
              <h4 class="md:hidden">
                {{ user.name }}
              </h4>
            </div>
          </div>
        </header>

        <div class="mx-auto px-4 sm:px-6 sm:mt-5 border-gray-200">
          <dl class="divide-y divide-gray-200">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Full name</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow"><input class="w-full" v-model="user.name" /></span>
                <span class="ml-4 flex-shrink-0">
                  <button
                    type="submit"
                    class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>

            <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Email address</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow">{{ user.email }}</span>
              </dd>
            </div>

            <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Phone</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow"><input class="w-full" v-model="user.phone" /></span>
                <span class="ml-4 flex-shrink-0">
                  <button
                    type="submit"
                    class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>
            <!-- Only commercial -->
            <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4" v-if="commercial">
              <dt class="text-sm font-medium text-gray-500">Job Title</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow"><input class="w-full" v-model="user.jobTitle" /></span>
                <span class="ml-4 flex-shrink-0">
                  <button
                    type="submit"
                    class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>
            <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Password</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow"></span>
                <span class="ml-4 flex-shrink-0">
                  <button
                    @click="showChangePassword = true"
                    class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Change Password
                  </button>
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <!-- Company details -->
      <section aria-labelledby="company-details-heading" v-if="commercial">
        <div class="shadow sm:rounded-md sm:overflow-hidden bg-white py-6 px-4 sm:p-6">
          <div>
            <h2 id="company-details-heading" class="text-lg leading-6 font-medium text-gray-900">
              Company information
            </h2>
            <p class="mt-1 text-sm text-gray-500">
              Update your company information. Please note that changes to the company will be
              reflected across all users of the company
            </p>
          </div>

          <dl class="divide-y divide-gray-200">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Company name</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow"><input class="w-full" v-model="company.name" /></span>
                <span class="ml-4 flex-shrink-0">
                  <button
                    type="submit"
                    class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>

            <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Preferred company email</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow"><input class="w-full" v-model="company.email" /></span>
                <span class="ml-4 flex-shrink-0">
                  <button
                    type="submit"
                    class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>

            <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Company phone</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow"><input class="w-full" v-model="company.phone" /></span>
                <span class="ml-4 flex-shrink-0">
                  <button
                    type="submit"
                    class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500 py-4 sm:py-5">
                Preferred method of payment
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 items-center">
                <span class="flex-grow">
                  <select
                    class="mt-1 block w-full border pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:border-indigo-500 sm:text-sm rounded-md"
                    v-model="company.preferredPayment"
                  >
                    <option value="credit-card">Credit card</option>
                    <option value="check">Check</option>
                    <option value="terms" v-if="company.creditLimit">Terms</option>
                  </select>
                </span>
                <span class="ml-4 flex-shrink-0">
                  <button
                    type="submit"
                    class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500 py-4 sm:py-5">
                Preferred method of delivery
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 items-center">
                <span class="flex-grow">
                  <select
                    class="mt-1 block w-full border pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:border-indigo-500 sm:text-sm rounded-md"
                    v-model="company.preferredDelivery"
                  >
                    <option value="call" v-if="allowDelivery">Will call</option>
                    <option value="local" v-if="allowDelivery">Local delivery</option>
                    <option value="standard">Standard shipping</option>
                    <option value="air">Air</option>
                    <option value="ground">Ground</option>
                    <option value="fedex">Fedex</option>
                  </select>
                </span>
                <span class="ml-4 flex-shrink-0">
                  <button
                    type="submit"
                    class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </section>

      <!-- Company Shipping Information -->
      <section aria-labelledby="company-shipping-details-heading" v-if="commercial">
        <div class="shadow sm:rounded-md sm:overflow-hidden bg-white py-6 px-4 sm:p-6">
          <div>
            <h2
              id="company-shipping-details-heading"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              Company Shipping information
            </h2>
            <div class="mt-1 text-sm text-gray-500 relative flex items-start">
              <div class="flex items-center h-5">
                <input
                  id="billing-different"
                  name="billing-different"
                  type="checkbox"
                  v-model="company.billingDifferent"
                  class="border h-4 w-4 text-blue-600 border-primary rounded"
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="billing-different" class="font-medium text-gray-700">
                  Different Billing
                </label>
                <span id="comments-description" class="text-gray-500">
                  check if the billing address is different from the shipping address.
                </span>
              </div>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-4 gap-6">
            <div class="col-span-4 sm:col-span-2">
              <label for="company-shipping-address" class="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                type="text"
                name="company-shipping-address"
                id="company-shipping-address"
                v-model="company.address"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
            <div class="col-span-4 sm:col-span-2">
              <label for="company-shipping-city" class="block text-sm font-medium text-gray-700">
                City
              </label>
              <input
                type="text"
                name="company-shipping-city"
                id="company-shipping-city"
                v-model="company.city"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
            <div class="col-span-4 sm:col-span-2">
              <label for="company-shipping-state" class="block text-sm font-medium text-gray-700">
                State
              </label>
              <div class="mt-1">
                <select
                  v-model="company.state"
                  name="company-shipping-state"
                  id="company-shipping-state"
                  autocomplete="address-level1"
                  class="block w-full border-gray-300 border rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                  required
                >
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
                </select>
              </div>
            </div>
            <div class="col-span-4 sm:col-span-2">
              <label for="company-postal-code" class="block text-sm font-medium text-gray-700">
                ZIP / Postal code
              </label>
              <input
                type="text"
                name="company-postal-code"
                id="company-postal-code"
                autocomplete="postal-code"
                @change="alterZip()"
                v-model="company.zip"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- Company Billing Information -->
      <section
        aria-labelledby="payment-details-heading"
        v-if="commercial && company.billingDifferent"
      >
        <div class="shadow sm:rounded-md sm:overflow-hidden bg-white py-6 px-4 sm:p-6">
          <div>
            <h2 id="payment-details-heading" class="text-lg leading-6 font-medium text-gray-900">
              Billing information
            </h2>
            <p class="mt-1 text-sm text-gray-500">
              Company billing information is only visible to you. Other users associated with
              {{ company.name }} will not see the billing information.
            </p>
          </div>

          <div class="mt-6 grid grid-cols-4 gap-6">
            <div class="col-span-4 sm:col-span-2">
              <label for="billing-address" class="block text-sm font-medium text-gray-700">
                Billing address
              </label>
              <input
                type="text"
                name="billing-address"
                id="billing-address"
                v-model="user.billingAddress"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
            <div class="col-span-4 sm:col-span-2">
              <label for="billing-city" class="block text-sm font-medium text-gray-700">
                Billing city
              </label>
              <input
                type="text"
                name="billing-city"
                id="billing-city"
                v-model="user.billingCity"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
            <div class="col-span-4 sm:col-span-2">
              <label for="billing-state" class="block text-sm font-medium text-gray-700">
                Billing state
              </label>
              <select
                v-model="user.billingState"
                name="billing-city"
                id="billing-city"
                autocomplete="address-level1"
                class="block w-full border-gray-300 border rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                required
              >
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="postal-code" class="block text-sm font-medium text-gray-700">
                Billing ZIP / Postal code
              </label>
              <input
                type="text"
                name="postal-code"
                id="postal-code"
                autocomplete="postal-code"
                v-model="user.billingZip"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- Retail user's shipping information -->
      <section aria-labelledby="payment-details-heading" v-if="!commercial">
        <div class="shadow sm:rounded-md sm:overflow-hidden bg-white py-6 px-4 sm:p-6">
          <div>
            <h2 id="payment-details-heading" class="text-lg leading-6 font-medium text-gray-900">
              Shipping information
            </h2>
            <div class="mt-1 text-sm text-gray-500 relative flex items-start">
              <div class="flex items-center h-5">
                <input
                  id="billing-different"
                  name="billing-different"
                  type="checkbox"
                  v-model="user.differentBilling"
                  class="border h-4 w-4 text-blue-600 border-primary rounded"
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="billing-different" class="font-medium text-gray-700">
                  Different Billing
                </label>
                <span id="comments-description" class="text-gray-500 text-xs">
                  select if the billing address for your company is different from the shipping
                  address.
                </span>
              </div>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-4 gap-6">
            <div class="col-span-4 sm:col-span-2">
              <label for="shipping-address" class="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                type="text"
                name="shipping-address"
                id="shipping-address"
                v-model="user.shippingAddress"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="shipping-city" class="block text-sm font-medium text-gray-700">
                City
              </label>
              <input
                type="text"
                name="shipping-city"
                id="shipping-city"
                v-model="user.shippingCity"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="shipping-state" class="block text-sm font-medium text-gray-700">
                State
              </label>
              <select
                v-model="user.shippingState"
                name="billing-city"
                id="billing-city"
                autocomplete="address-level1"
                class="block w-full border-gray-300 border rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                required
              >
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="postal-code" class="block text-sm font-medium text-gray-700">
                ZIP / Postal code
              </label>
              <input
                type="text"
                name="postal-code"
                id="postal-code"
                autocomplete="postal-code"
                v-model="user.shippingZip"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- Retail User's Billing Information -->
      <section
        aria-labelledby="payment-details-heading"
        v-if="!commercial && user.differentBilling"
      >
        <div class="shadow sm:rounded-md sm:overflow-hidden bg-white py-6 px-4 sm:p-6">
          <div>
            <h2 id="payment-details-heading" class="text-lg leading-6 font-medium text-gray-900">
              Billing information
            </h2>
          </div>
          <div class="mt-6 grid grid-cols-4 gap-6">
            <div class="col-span-4 sm:col-span-2">
              <label for="billing-address" class="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                type="text"
                name="billing-address"
                id="billing-address"
                v-model="user.billingAddress"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
            <div class="col-span-4 sm:col-span-2">
              <label for="billing-city" class="block text-sm font-medium text-gray-700">
                City
              </label>
              <input
                type="text"
                name="billing-city"
                id="billing-city"
                v-model="user.billingCity"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
            <div class="col-span-4 sm:col-span-2">
              <label for="billing-state" class="block text-sm font-medium text-gray-700">
                State
              </label>
              <div class="mt-1">
                <select
                  v-model="user.billingState"
                  name="billing-city"
                  id="billing-city"
                  autocomplete="address-level1"
                  class="block w-full border-gray-300 border rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                  required
                >
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
                </select>
              </div>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="postal-code" class="block text-sm font-medium text-gray-700">
                ZIP / Postal code
              </label>
              <input
                type="text"
                name="postal-code"
                id="postal-code"
                autocomplete="postal-code"
                v-model="user.billingZip"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="flex justify-center sm:justify-end">
        <button type="submit" class="btn-primary rounded">Save changes</button>
      </section>
    </form>

    <div v-if="showChangePassword">
      <PopUp title="Change Password" @close="showChangePassword = false">
        <form
          @submit.prevent="updatePassword"
          class="md:px-4 md:py-2 md:h-auto p-4 px-8 h-3/4-screen"
        >
          <div class="flex flex-col my-4">
            <label for="currentPassword" class="text-sm font-normal flex"
              >Current Password
              <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip mx-1">
                <font-awesome-icon class="text-lg" icon="info-circle" />
                <span
                  class="tooltiptext shadow text-black mx-1 p-1 flex flex-col w-64 md:w-128 text-left left-0"
                >
                  If you have previously forgotten your password, request a new password. Then enter
                  the new password in as the "Current Password". Each password is only a single use
                  password.
                </span>
              </div>
            </label>
            <input
              type="password"
              id="currentPassword"
              class="styled border-gray-300 border"
              v-model="user.password"
              required
            />
          </div>

          <div class="flex flex-col my-4">
            <label for="newPassword" class="text-sm font-normal">New Password</label>
            <input
              type="password"
              id="newPassword"
              class="styled border-gray-300 border"
              v-model="password"
              required
            />
          </div>

          <div class="flex flex-col my-4 mb-2">
            <label for="confirmation-password" class="text-sm font-normal">Confirm Password</label>
            <input
              type="password"
              id="confirmation-password"
              class="styled border-gray-300 border"
              v-model="confirmationPassword"
              required
            />
          </div>
          <div
            class="text-primary my-2 font-medium cursor-pointer hover:underline"
            @click="resetPassword"
          >
            Forgot your password?
          </div>

          <div v-if="error" class="text-red mb-4">{{ error }}</div>
          <div class="flex justify-end">
            <button class="btn-action md:rounded rounded-full w-full md:w-48 mt-8">
              Confirm Change
            </button>
          </div>
        </form>
      </PopUp>
    </div>
    <PopUp v-if="showUploader" @close="showUploader = false" title="Upload Avatar">
      <Uploader
        :title="false"
        @close="showUploader = false"
        @setValues="setUserImage"
        @uploadFinished="showUploader = false"
      ></Uploader>
    </PopUp>
  </div>
</template>

<script>
import PopUp from '@/components/shared/PopUp';
import Uploader from '@/components/shared/Uploader';
const zipCodes = require('@/assets/utils/zipcodes.js').default;

export default {
  data: function () {
    return {
      toggleWidth: 60,
      color: '#005785',
      altered: false,
      showChangePassword: false,
      confirmationPassword: null,
      password: null,
      error: null,
      showUploader: false,
      allowDelivery: false,
    };
  },
  computed: {
    commercial() {
      return this.$store.getters.getCommercial;
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    primary() {
      if (this.user.accountRole === 'Primary') return true;
      return false;
    },
    company() {
      return this.$store.getters['company/getCompany'];
    },
  },
  watch: {
    company() {
      if (this.company) {
        this.alterZip();
      }
    },
  },
  methods: {
    alterZip() {
      if (zipCodes.indexOf(parseInt(this.company.zip)) > 0) {
        this.allowDelivery = true;
      } else {
        this.allowDelivery = false;
      }
    },
    setUserImage(data) {
      this.user.avatar = data.path;
    },
    updateUser() {
      if (this.commercial) {
        delete this.company.orders;
        delete this.company.users;
        delete this.company.products;
        delete this.company.tiers;

        this.$store.dispatch('company/updateCompany', this.company);
      }

      if (!this.commercial && !this.user.differentBilling) {
        this.user.billingAddress = this.user.shippingAddress;
        this.user.billingCity = this.user.shippingCity;
        this.user.billingState = this.user.shippingState;
        this.user.billingZip = this.user.shippingZip;
      }

      delete this.user.company;
      this.$store.dispatch('user/updateUser', this.user);

      this.altered = false;
    },
    async updatePassword() {
      if (this.password !== null) {
        if (this.password !== this.confirmationPassword) {
          this.error = 'Passwords do not match';
          return;
        }
        this.user.newPassword = this.password;
        this.error = null;
        if (await this.$store.dispatch('user/updatePassword', this.user)) {
          this.showChangePassword = false;
        }
      }
    },
    resetPassword() {
      this.$store.dispatch('user/resetPassword', this.user.email);
    },
  },
  mounted() {
    if (this.user && this.user.company) {
      this.$store.dispatch('company/getCompany', this.user.company._id);
    }
  },
  components: {
    PopUp,
    Uploader,
  },
};
</script>
