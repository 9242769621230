<template>
  <div>
    <div class="hidden md:block h-auto">
      <ModalPopup :title="title" :body="body" :controlWidth="controlWidth" @close="$emit('close')">
        <slot></slot>
      </ModalPopup>
    </div>
    <Overlay class="md:hidden" :position="position" @close="$emit('close')"><slot> </slot></Overlay>
  </div>
</template>

<script>
import Overlay from './MobileOverlay';
import ModalPopup from './DesktopModal';

export default {
  data: function () {
    return {};
  },
  props: {
    title: { type: String, default: null },
    body: String,
    controlWidth: { type: Boolean, default: false },
    position: {
      type: String,
      default: 'middle',
    },
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  components: { ModalPopup, Overlay },
};
</script>
<style scoped></style>
