<!--
    Uploader.vue

    Component for uploading images. After the image has been successfuly
    saved to the database, the server sends back the path to the image
    inside of the response. This component then emits an event called
    setValues the parameter of the second implicit parameter that is 
    passed to the parent function "setValues" is of type
    {
      path: img.path,
      error: response.error,
      color: "green",
    }

    Error doesn't only contain an error, it simply contains the value
    message returned from the server.

    Example:
    <Uploader v-if="showUploader" @setValues="setValues" @uploadFinished="showUploader = false" @escape="showUploader = false"></Uploader>
-->

<template>
  <div class="text-center">
    <h2 class="text-primary" v-if="title">Upload Image</h2>
    <form @submit.prevent="upload" enctype="multipart/form-data">
      <input type="file" name="photo" class="styled w-64 my-6" @change="fileChanged" />
      <div class="text-center md:text-right">
        <button type="submit" class="btn-action rounded text-lg">
          {{ buttonText }}
        </button>
        <button @click="$emit('close')" class="btn-white rounded text-lg ml-4">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Uploader',
  props: {
    title: { type: Boolean, default: true },
    buttonText: { type: String, default: 'Upload' },
    variant: { type: Boolean, default: false },
  },
  data() {
    return {
      file: null,
      thumbnail: null,
      path: '',
      thumbnailPath: '',
    };
  },
  methods: {
    fileChanged(event) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      if (this.file.size > 2000000) {
        this.$store.commit('setError', {
          message: 'File Uploaded is too large, please reduce the file size',
          color: 'red',
        });
        this.$emit('close');
        return;
      }
    },

    upload: async function () {
      if (this.file === null) {
        this.$emit('noFileSave', null);
        return;
      }
      //upload full size if it is a variant.

      const formData = new FormData();

      formData.append('photo', this.file, this.fileName);

      try {
        let response = await this.$store.dispatch('uploadSingle', formData);
        this.thumbnail = `${response[0].replace(/1000/g, 300)}`;
        this.path = `${response[0]}`;
        this.file = null;
      } catch (error) {
        console.log(error);
        this.$store.commit('setError', {
          message: error,
          color: 'red',
        });
        return;
      }
      this.$emit('setValues', { path: this.path, thumbnail: this.thumbnail });
      this.$emit('uploadFinished');
    },
  },
};
</script>
