<template>
  <div class="px-4 sm:px-6 lg:max-w-6xl lg:px-8 z-10 relative sm:py-16">
    <h2 class="sr-only">Checkout</h2>

    <div class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
      <form @submit.prevent="pay">
        <div class="grid grid-cols-2 gap-4">
          <h2 class="text-lg font-medium text-gray-900">Billing information</h2>

          <div class="sm:col-span-2 gap-y-6">
            <div>
              <label for="first-name" class="block text-sm font-medium text-primary">Name</label>
              <div class="mt-1">
                <input
                  type="text"
                  id="first-name"
                  name="first-name"
                  autocomplete="given-name"
                  required
                  v-model="order.name"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div class="sm:col-span-2 gap-y-6">
            <div>
              <label for="email-address" class="block text-sm font-medium text-primary">
                Email
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  id="email-address"
                  name="email-address"
                  autocomplete="email-address"
                  required
                  v-model="order.email"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="address" class="block text-sm font-medium text-primary">Address</label>
            <div class="mt-1">
              <input
                type="text"
                name="address"
                id="address"
                required
                v-model="order.billingAddress"
                autocomplete="street-address"
                class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label for="city" class="block text-sm font-medium text-primary">City</label>
            <div class="mt-1">
              <input
                type="text"
                name="city"
                id="city"
                required
                autocomplete="address-level2"
                v-model="order.billingCity"
                class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label for="region" class="block text-sm font-medium text-primary"
              >State / Province</label
            >
            <div class="mt-1">
              <input
                type="text"
                name="region"
                id="region"
                required
                autocomplete="address-level1"
                v-model="order.billingState"
                class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label for="postal-code" class="block text-sm font-medium text-primary"
              >Postal code</label
            >
            <div class="mt-1">
              <input
                type="text"
                name="postal-code"
                id="postal-code"
                required
                autocomplete="postal-code"
                v-model="order.billingZip"
                class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <!-- Payment -->
        <div class="mt-10 border-t border-gray-400 pt-10">
          <h2 class="text-lg font-medium text-gray-900">Payment</h2>
          <div v-if="loading" class="relative h-48">
            <Loading :whiteSpace="false" text="processing..." />
          </div>
          <div v-else>
            <div id="app" class="rounded py-4">
              <card
                class="stripe-card my-2 mx-auto border border-gray-200 p-2 bg-white"
                :class="{ complete }"
                :stripe="stripeKey"
                @change="complete = $event.complete"
              />
              <div v-if="error" class="text-red">{{ error }}</div>
              <div class="flex justify-end">
                <img src="/images/static/1.png" class="h-10 mx-2" alt="" />
                <img src="/images/static/2.png" class="h-10 mx-2" alt="" />
                <img src="/images/static/3.png" class="h-10 mx-2" alt="" />
              </div>
              <div class="">
                <button
                  type="submit"
                  class="pay-with-stripe btn-action w-full rounded-full md:rounded my-4 mx-auto"
                >
                  Confirm payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <!-- Order summary -->
      <div class="mt-10 lg:mt-0">
        <NewProductSummary
          :products="cart"
          :shipping="orderTotal.shipping"
          :discount="orderTotal.discount"
          :taxRate="order.taxRate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewProductSummary from '@/components/checkout/NewProductSummary.vue';
import Loading from '@/components/shared/Loading.vue';
import { Card, createToken } from 'vue-stripe-elements-plus';
import keys from '@/keys.json';

export default {
  data: function () {
    return {
      loading: false,
      stripeOptions: {},
      stripeKey: keys.STRIPE_KEY,
      error: null,
      complete: false,
    };
  },
  computed: {
    order() {
      return this.$store.getters['delayedPayments/getOrder'];
    },
    cart() {
      return this.$store.getters['delayedPayments/getCart'];
    },
    orderTotal() {
      return this.$store.getters['delayedPayments/getOrderTotal'];
    },
    finished() {
      return this.$store.getters['delayedPayments/getFinished'];
    },
  },
  watch: {
    finished() {
      if (this.finished) {
        this.loading = false;
        const order_id = this.order.order_id;
        this.$store.commit('delayedPayments/clear');
        this.$router.push(`/user/orders/${order_id}`);
      }
    },
  },
  mounted() {},
  methods: {
    async pay() {
      this.stripeOptions.name = this.order.name;
      this.stripeOptions.address_line1 = this.order.billingAddress;
      this.stripeOptions.address_city = this.order.billingCity;
      this.stripeOptions.address_state = this.order.billingState;
      this.stripeOptions.address_zip = this.order.billingZip;

      const response = await createToken(this.stripeOptions);
      if (response.token) {
        this.loading = true;
        this.$store.dispatch('delayedPayments/markOrderAsPayed', {
          order: {
            ...this.orderTotal,
            ...this.order,
          },
          stripeToken: response.token,
        });
      } else {
        this.error = 'Credit Card validation has failed, please use a different card.';
      }
    },
  },
  components: { Card, NewProductSummary, Loading },
};
</script>
